import React, { useState, useEffect } from "react";
import {
  Button,
  TextInput,
  Checkbox,
  NumberInput,
  Window,
  WindowContent,
  WindowHeader,
  Hourglass,
  Slider,
  GroupBox,
} from "react95";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import { useProgram } from "../App";
import BN from "bn.js";
import { useError } from "../context/ErrorContext";
import { useAlert } from "../context/AlertContext";
import validator from "validator";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const { Buffer } = require("buffer");

// Style for the form
const CompetitionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

// Wrapper for the modal content
const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const ResponsiveWindow = styled(Window)`
  width: 100%;
  max-width: 600px;
  pointer-events: auto;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
`;

const TokenInfo = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 32px;
    height: 32px;
  }
`;

const InputWithButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchButton = styled(Button)`
  width: 90px;
`;

const SliderWrapper = styled.div`
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const SliderDetails = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
`;

const customTheme = createTheme({
  palette: {
    mode: "dark", // Dark theme
    primary: {
      main: "#64b5f6", // Primary color
    },
    background: {
      default: "#121212", // Background color
      paper: "#1e1e1e", // Dialog background
    },
  },
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#90caf9", // Default day color
          "&.Mui-selected": {
            backgroundColor: "#64b5f6", // Selected day background
            color: "#ffffff", // Selected day text color
          },
          "&:hover": {
            backgroundColor: "#42a5f5", // Hover effect
          },
        },
      },
    },
    MuiClock: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e", // Clock background
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#64b5f6", // Input border color
            },
            "&:hover fieldset": {
              borderColor: "#ffffff", // Hover border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#90caf9", // Focus border color
            },
          },
          "& .MuiInputBase-input": {
            color: "#ffffff", // Input text color
          },
          "& .MuiInputLabel-root": {
            color: "#90caf9", // Label color
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#ffffff", // Focused label color
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212", // Dialog content background
        },
      },
    },
  },
});

// Validate Telegram URL
const isValidTelegram = (url) => {
  // Allow URLs with or without protocol
  const telegramRegex =
    /^(https?:\/\/)?(t\.me|telegram\.me)\/[A-Za-z0-9_]{5,32}$/;
  return telegramRegex.test(url);
};

// Validate Discord URL
const isValidDiscord = (url) => {
  // Allow URLs with or without protocol
  const discordRegex = /^(https?:\/\/)?(www\.)?discord\.gg\/[A-Za-z0-9]+$/;
  return discordRegex.test(url);
};

// Validate X (Twitter) URL
const isValidX = (url) => {
  // Allow URLs with or without protocol
  const xRegex =
    /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]{1,15}$/;
  return xRegex.test(url);
};

// Validate general URL (for website) without requiring protocol
const isValidURL = (url) => {
  return validator.isURL(url, {
    require_protocol: false, // Protocol is optional
    require_valid_protocol: false, // Do not require a valid protocol
    protocols: ["http", "https"], // Allow only http and https protocols if present
  });
};

// Helper function to validate Solana public keys
const isValidSolanaAddress = (address) => {
  try {
    new web3.PublicKey(address);
    return true;
  } catch (error) {
    return false;
  }
};

// Helper function to prepend https:// if missing
const formatURL = (url) => {
  if (!url) return ""; // Return empty string if no URL provided
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const TextInputStyled = styled(TextInput)`
  border: ${(props) => (props.isInvalid ? "2px solid red" : "1px solid #ccc")};
`;

// Styled component for inline error messages
const ErrorMessage = styled.p`
  color: red;
  font-size: 0.85rem;
  margin-top: -8px;
  margin-bottom: 8px;
`;

const CompetitionPage = () => {
  const [formData, setFormData] = useState({
    contractAddress: "",
    ticketPrice: 0,
    seedAmount: 0,
    endDate: "",
    endTime: "",
    customAllocationEnabled: false,
    poolPercentage: 20,
    description: "",
    telegramLink: "",
    discordLink: "",
    websiteLink: "",
    xLink: "",
  });
  const { showError, clearError } = useError();
  const { showAlert } = useAlert();
  const [selectedToken, setSelectedToken] = useState(null);
  const [feePercentages, setFeePercentages] = useState({
    totalFeesPercentage: 5, // Default value
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { wallet, publicKey } = useWallet();
  const { connection } = useConnection();
  const { program } = useProgram();
  const [isWalletErrorDismissed, setIsWalletErrorDismissed] = useState(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const ENFORCE_MIN_5_MINUTES = true;

  useEffect(() => {
    if (!publicKey && !isWalletErrorDismissed) {
      showError("Please connect your wallet first.");
    } else if (publicKey && !isWalletErrorDismissed) {
      clearError();
      setIsWalletErrorDismissed(true); // stops repeated clearing
    }
  }, [publicKey, isWalletErrorDismissed, showError, clearError]);

  // Fetch fee percentages from backend
  useEffect(() => {
    const fetchProtocolConfig = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/protocol-config`);
        const feeConfigMap = response.data.reduce((acc, item) => {
          acc[item.key] = parseFloat(item.value);
          return acc;
        }, {});
        const protocol_fee_percentage =
          feeConfigMap.PROTOCOL_FEE_PERCENTAGE || 0;
        const creator_fee_percentage = feeConfigMap.CREATOR_FEE_PERCENTAGE || 0;
        const global_jackpot_fee_percentage =
          feeConfigMap.GLOBAL_JACKPOT_FEE_PERCENTAGE || 0;
        const influencer_fee_percentage =
          feeConfigMap.INFLUENCER_FEE_PERCENTAGE || 0;
        const totalFeesPercentage =
          protocol_fee_percentage +
          creator_fee_percentage +
          global_jackpot_fee_percentage +
          influencer_fee_percentage;

        const globalJackpotEndMS = feeConfigMap.GLOBAL_JACKPOT_END_TIME || 0;
        console.log("Global Jackpot End:", globalJackpotEndMS);
        const globalJackpotDeadline = new Date(
          globalJackpotEndMS * 1000 - 60_000
        );
        console.log("Global Jackpot Deadline:", globalJackpotDeadline);
        setFeePercentages({
          protocol_fee_percentage,
          creator_fee_percentage,
          global_jackpot_fee_percentage,
          influencer_fee_percentage,
          totalFeesPercentage,
          globalJackpotEndMS,
          globalJackpotDeadline,
        });
      } catch (error) {
        console.error("Error fetching protocol configuration:", error);
        setErrors((prev) => ({
          ...prev,
          protocol: "Failed to fetch protocol configuration.",
        }));
      }
    };
    fetchProtocolConfig();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });

    let errorMsg = "";
    switch (name) {
      case "telegramLink":
        if (value && !isValidTelegram(value)) {
          errorMsg = "Please enter a valid Telegram link.";
        }
        break;
      case "discordLink":
        if (value && !isValidDiscord(value)) {
          errorMsg = "Please enter a valid Discord link.";
        }
        break;
      case "xLink":
        if (value && !isValidX(value)) {
          errorMsg = "Please enter a valid X (Twitter) link.";
        }
        break;
      case "websiteLink":
        if (value && !isValidURL(value)) {
          errorMsg = "Please enter a valid Website link.";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [name]: errorMsg });
  };

  const fetchTokenInfo = async () => {
    setIsLoading(true);
    try {
      if (!formData.contractAddress) {
        showError("Please enter a valid contract address.");
        setIsLoading(false);
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/api/token-info`, {
        params: { address: formData.contractAddress },
      });

      if (response.data && response.data.data) {
        const tokenData = response.data.data;

        console.log(
          "Token Info fetched successfully:",
          JSON.stringify(tokenData, null, 2)
        );

        setSelectedToken(tokenData);

        // Extract metadata and extensions
        const metadata = tokenData.metadata || {};
        const extensions = metadata.extensions || {};

        // 1) Auto-populate ticketPrice to 0.5
        // 2) Set end date/time 10 min in the future
        const tenMinutesLater = new Date(Date.now() + 10 * 60_000);
        const newDate = tenMinutesLater.toISOString().split("T")[0]; // "YYYY-MM-DD"
        const newTime = tenMinutesLater.toISOString().split("T")[1].slice(0, 5); // "HH:MM"

        // Update formData with fetched values
        setFormData((prevFormData) => ({
          ...prevFormData,
          description: metadata.description || "",
          telegramLink: extensions.telegram || "",
          discordLink: extensions.discord || "",
          websiteLink: extensions.website || "",
          xLink: extensions.twitter || "",
          ticketPrice: 0.5,
          endDate: newDate,
          endTime: newTime,
        }));
      } else {
        showError("Token not found. Please check the contract address.");
      }
    } catch (error) {
      console.error("Error fetching token metadata:", error.message);
      showError(
        error.response?.data?.errors?.message ||
          "Failed to fetch token information. Please check the contract address."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetTokenInfo = () => {
    // Reset the form data to the initial default state
    setFormData({
      contractAddress: "",
      ticketPrice: 0,
      seedAmount: 0,
      endDate: "",
      endTime: "",
      customAllocationEnabled: false,
      poolPercentage: 20,
      description: "",
      telegramLink: "",
      discordLink: "",
      websiteLink: "",
      xLink: "",
    });

    // Clear the selected token and any error messages
    setSelectedToken(null);
    clearError();
  };

  const handleNumberChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSliderChange = (newValue) => {
    setFormData({ ...formData, poolPercentage: newValue });
  };

  // Helper to check form validity for enabling the submit button
  const isFormValid = () => {
    const {
      contractAddress,
      ticketPrice,
      endDate,
      endTime,
      description,
      seedAmount,
      customAllocationEnabled,
      poolPercentage,
    } = formData;

    if (
      !contractAddress ||
      !ticketPrice ||
      ticketPrice <= 0 ||
      !endDate ||
      !endTime ||
      !description.trim()
    ) {
      return false;
    }
    if (seedAmount < 0) {
      return false;
    }
    if (
      customAllocationEnabled &&
      (!poolPercentage || poolPercentage < 5 || poolPercentage > 90)
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Collect errors in an array
    const errorsArr = [];
    const { telegramLink, discordLink, websiteLink, xLink, contractAddress } =
      formData;

    const userEndTimeMS = new Date(
      `${formData.endDate}T${formData.endTime}`
    ).getTime();
    const deadlineMS = feePercentages.globalJackpotEndMS * 1000 - 60_000;
    if (userEndTimeMS > deadlineMS) {
      errorsArr.push(
        "End date/time cannot exceed the global jackpot deadline (1 min before)."
      );
    }

    const numericPrice = parseFloat(formData.ticketPrice);

    // 1) Basic field checks
    if (!contractAddress) {
      errorsArr.push("Contract address is required.");
    } else if (!isValidSolanaAddress(contractAddress)) {
      errorsArr.push("Please enter a valid Solana contract address.");
    }
    if (!selectedToken) {
      errorsArr.push("Please search and validate the token address.");
    }
    if (isNaN(numericPrice) || numericPrice <= 0 || numericPrice < 0.01) {
      errorsArr.push(
        "Ticket price must be a positive number greater than or equal to 0.01 SOL"
      );
    }

    if (!formData.endDate) {
      errorsArr.push("End date is required.");
    }
    if (!formData.endTime) {
      errorsArr.push("End time is required.");
    }
    if (!formData.description.trim()) {
      errorsArr.push("Description is required.");
    }
    if (formData.seedAmount < 0) {
      errorsArr.push("Seed amount cannot be negative.");
    }

    if (formData.customAllocationEnabled) {
      if (
        !formData.poolPercentage ||
        formData.poolPercentage < 5 ||
        formData.poolPercentage > 90
      ) {
        errorsArr.push("Pool percentage must be between 5 and 90.");
      }
    }

    // 3) Optional Link checks
    if (telegramLink && !isValidTelegram(telegramLink)) {
      errorsArr.push("Please enter a valid Telegram link.");
    }
    if (discordLink && !isValidDiscord(discordLink)) {
      errorsArr.push("Please enter a valid Discord link.");
    }
    if (xLink && !isValidX(xLink)) {
      errorsArr.push("Please enter a valid X (Twitter) link.");
    }
    if (websiteLink && !isValidURL(websiteLink)) {
      errorsArr.push("Please enter a valid Website link.");
    }

    // Show them all at once if there's any error
    if (errorsArr.length > 0) {
      showError(errorsArr.join("\n"));
      setIsLoading(false);
      return;
    }

    // Proceed if no errors
    try {
      if (!publicKey || !wallet) {
        throw new Error("Wallet not connected.");
      }
      if (!(publicKey instanceof web3.PublicKey)) {
        throw new Error("Invalid public key.");
      }
      // Derive the PDA for the creator
      const [creatorPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("creator"), publicKey.toBuffer()],
        program.programId
      );

      console.log("Creator PDA:", creatorPDA.toBase58());

      // Check if creatorAccount exists
      const creatorAccount = await connection.getAccountInfo(creatorPDA);
      if (!creatorAccount) {
        console.log("Initializing creator account...");

        // creatorAccount check...
        const instruction = await program.methods
          .initializeCreator()
          .accounts({
            creator: creatorPDA,
            authority: publicKey,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();

        const transaction = new web3.Transaction().add(instruction);

        // Get both the blockhash and lastValidBlockHeight
        const { blockhash, lastValidBlockHeight } =
          await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = publicKey;

        const signedTransaction = await wallet.adapter.signTransaction(
          transaction
        );

        // 1) Send
        const signature = await connection.sendRawTransaction(
          signedTransaction.serialize(),
          { skipPreflight: false, preflightCommitment: "processed" }
        );
        console.log("Creator account transaction signature:", signature);

        // 2) Confirm
        await connection.confirmTransaction(
          {
            signature,
            blockhash,
            lastValidBlockHeight,
          },
          "finalized" // or "finalized"
        );

        const creatorAccountInfo = await connection.getAccountInfo(creatorPDA, {
          commitment: "finalized",
        });

        if (!creatorAccountInfo) {
          throw new Error(
            "Failed to fetch creator account data even at finalized commitment."
          );
        }

        console.log("Creator account transaction CONFIRMED!");
      } else {
        console.log("Creator account already exists.");
      }

      // Fetch creator account data
      console.log("Fetching creator account data...");
      const creatorAccountInfo = await connection.getAccountInfo(creatorPDA);
      if (!creatorAccountInfo) {
        throw new Error("Failed to fetch creator account data.");
      }

      const dataView = new DataView(creatorAccountInfo.data.buffer);

      // competition_count starts at byte offset 72
      const competitionCount = Number(dataView.getBigUint64(72, true)); // little-endian
      console.log("Competition count:", competitionCount);

      // Create the competitionCountBuffer
      const competitionCountBuffer = Buffer.alloc(8);
      competitionCountBuffer.writeBigUInt64LE(BigInt(competitionCount));

      // Derive the PDA for the competition
      const [competitionPDA] = await web3.PublicKey.findProgramAddress(
        [
          Buffer.from("competition"),
          creatorPDA.toBuffer(),
          competitionCountBuffer,
        ],
        program.programId
      );

      console.log("Competition PDA:", competitionPDA.toBase58());

      // Derive the PDA for the protocol_config (assuming it has been initialized already)
      const [protocolConfigPDA] = await web3.PublicKey.findProgramAddress(
        [Buffer.from("protocol_config")],
        program.programId
      );

      // Parse form data and convert values to BN
      const endTimestamp = new BN(
        Math.floor(
          new Date(`${formData.endDate}T${formData.endTime}`).getTime() / 1000
        )
      );
      const formattedTicketPrice = new BN(
        Math.floor(formData.ticketPrice * web3.LAMPORTS_PER_SOL)
      ); // Convert to lamports
      const formattedSeedAmount = new BN(
        Math.floor(formData.seedAmount * web3.LAMPORTS_PER_SOL)
      );

      const poolPercentage = formData.customAllocationEnabled
        ? formData.poolPercentage
        : 20;

      const tokenBuyPercentage =
        100 - poolPercentage - feePercentages.totalFeesPercentage;

      // Ensure tokenBuyPercentage is a valid number
      if (isNaN(tokenBuyPercentage)) {
        throw new Error("Invalid tokenBuyPercentage calculation");
      }

      console.log("Token Buy Percentage:", tokenBuyPercentage); // Debugging line

      const poolPercentageBN = new BN(poolPercentage);

      console.log("Competition creation parameters:", {
        description: formData.description,
        ticketPrice: formattedTicketPrice.toString(),
        endTime: endTimestamp.toString(),
        tokenBuyPercentage: tokenBuyPercentage.toString(),
        poolPercentage: poolPercentageBN.toString(),
        tokenAddress: formData.contractAddress,
        seedAmount: formattedSeedAmount.toString(),
      });

      const tokenBuyPercentageBPS = tokenBuyPercentage * 100;
      const poolPercentageBPS = poolPercentage * 100;

      // Create PublicKey for token address
      const tokenPubkey = new web3.PublicKey(formData.contractAddress);
      console.log("Token PublicKey:", tokenPubkey.toBase58());

      // Generate the instruction for creating the competition
      const instruction = await program.methods
        .createCompetition(
          formData.description,
          formattedTicketPrice,
          endTimestamp,
          tokenBuyPercentageBPS,
          poolPercentageBPS,
          tokenPubkey,
          formattedSeedAmount
        )
        .accounts({
          creator: creatorPDA,
          authority: publicKey,
          competition: competitionPDA,
          protocolConfig: protocolConfigPDA,
          systemProgram: web3.SystemProgram.programId,
        })
        .instruction();

      // Create a transaction and add the instruction
      const transaction = new web3.Transaction().add(instruction);

      // Fetch the latest blockhash
      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      console.log(
        "Transaction prepared for competition creation:",
        transaction
      );

      // Attempt to sign and send the transaction
      if (wallet.adapter.signTransaction) {
        console.log("Using wallet adapter for signing competition creation...");
        const signedTransaction = await wallet.adapter.signTransaction(
          transaction
        );

        console.log(
          "Signed transaction for competition creation:",
          signedTransaction
        );

        const txSignature = await connection.sendRawTransaction(
          signedTransaction.serialize(),
          {
            skipPreflight: false,
            preflightCommitment: "processed",
          }
        );

        console.log("Competition created with signature:", txSignature);

        // 2) Confirm the transaction
        await connection.confirmTransaction(
          {
            signature: txSignature,
            blockhash,
            lastValidBlockHeight,
          },
          "processed" // or "finalized"
        );
        console.log("Competition creation transaction CONFIRMED!");

        // Construct the Solana link automatically
        const solanaLink = `https://solscan.io/token/${formData.contractAddress}`;

        // Format URLs to ensure they have protocols
        const formattedTelegramLink = formatURL(formData.telegramLink);
        const formattedDiscordLink = formatURL(formData.discordLink);
        const formattedWebsiteLink = formatURL(formData.websiteLink);
        const formattedXLink = formatURL(formData.xLink);

        console.log("Payload being sent to backend:", {
          creator_id: publicKey.toBase58(),
          ticket_price: formData.ticketPrice,
          duration: Math.floor(endTimestamp.toNumber() - Date.now() / 1000),
          end_time: new Date(endTimestamp.toNumber() * 1000).toISOString(),
          token: formData.contractAddress,
          poolPercentage, // Log this explicitly
          seed_amount: formData.seedAmount,
          description: formData.description,
          txSignature, // Include transaction signature for verification
          pda_address: competitionPDA.toBase58(), // Include the PDA address
          token_buy_percentage: tokenBuyPercentage, // Log this explicitly
          token_name: selectedToken?.name || "", // Include token name
          token_symbol: selectedToken?.symbol || "", // Include token symbol
          token_icon: selectedToken?.icon || "", // Include token icon
          telegram_link: formattedTelegramLink || null,
          discord_link: formattedDiscordLink || null,
          solana_link: solanaLink, // Include the automatically generated Solana link
          website_link: formattedWebsiteLink || null,
          x_link: formattedXLink || null,
        });

        // Add backend API call here
        try {
          const response = await axios.post(`${API_BASE_URL}/competitions`, {
            creator_id: publicKey.toBase58(),
            ticket_price: formData.ticketPrice,
            duration: Math.floor(endTimestamp.toNumber() - Date.now() / 1000),
            end_time: new Date(endTimestamp.toNumber() * 1000).toISOString(),
            token: formData.contractAddress,
            pool_percentage: poolPercentage,
            seed_amount: formData.seedAmount,
            description: formData.description,
            txSignature, // Include transaction signature for verification
            pda_address: competitionPDA.toBase58(), // Include the PDA address
            token_buy_percentage: tokenBuyPercentage,
            token_name: selectedToken?.name || "", // Include token name
            token_symbol: selectedToken?.symbol || "", // Include token symbol
            token_icon: selectedToken?.icon || "", // Include token icon
            telegram_link: formattedTelegramLink || null,
            discord_link: formattedDiscordLink || null,
            solana_link: solanaLink, // Include the automatically generated Solana link
            website_link: formattedWebsiteLink || null,
            x_link: formattedXLink || null,
          });

          const truncatedSignature =
            txSignature.slice(0, 4) + "..." + txSignature.slice(-4);

          showAlert(
            <div style={{ textAlign: "center" }}>
              Competition created successfully! <br /> Signature:&nbsp;
              <a
                href={`https://explorer.solana.com/tx/${txSignature}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  transition: "color 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "blue";
                }}
              >
                {truncatedSignature}
              </a>
            </div>,
            "success"
          );
          navigate(`/competition/${response.data.competition.id}`);

          // Optional: Reset form after successful submission
          setFormData({
            contractAddress: "",
            ticketPrice: 0,
            seedAmount: 0,
            endDate: "",
            endTime: "",
            customAllocationEnabled: false,
            poolPercentage: 20,
            description: "",
            telegramLink: "",
            discordLink: "",
            websiteLink: "",
            xLink: "",
          });
          setSelectedToken(null);
          setErrors({});
        } catch (apiError) {
          console.error("Error sending data to the backend:", apiError);
          showError(
            "Failed to save competition to the backend. Please try again."
          );
        }
      } else {
        throw new Error("Wallet does not support signing transactions.");
      }
    } catch (error) {
      console.error("Error during competition creation:", error);
      showError(
        error.message || "Failed to create competition. Please try again."
      );
    }
  };

  let windowTitle = "CreateCompetition.eXe";
  if (selectedToken) {
    // e.g. 100 - poolPercentage - totalFees = buyTokens
    const buyTokens =
      100 - formData.poolPercentage - feePercentages.totalFeesPercentage;
    // build "PEPE_75_20" or something
    windowTitle = `${selectedToken.symbol}_${buyTokens}_${formData.poolPercentage}.eXe`;
  }

  const handleBackNavigation = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Wrapper>
      <ResponsiveWindow>
        <WindowHeader
          className="window-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* 3) Floating Icon */}
          {selectedToken?.icon && (
            <img
              src={selectedToken.icon}
              alt={selectedToken?.symbol}
              style={{
                position: "absolute",
                left: 5,
                top: 5,
                width: 24,
                height: 24,
                pointerEvents: "none",
              }}
            />
          )}

          {/* 4) Updated header text (with margin if icon is present) */}
          <span style={{ marginLeft: selectedToken?.icon ? "30px" : 0 }}>
            {windowTitle}
          </span>
          <Button
            onClick={handleBackNavigation}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeader>
        <WindowContent>
          <CompetitionForm onSubmit={handleSubmit}>
            <label
              style={{
                marginTop: "1rem", // Add 1rem margin on top
              }}
            >
              Token Address
            </label>
            <InputWithButton>
              <TextInput
                placeholder="Enter Contract Address"
                name="contractAddress"
                value={formData.contractAddress}
                onChange={handleChange}
                fullWidth
              />
              <SearchButton
                type="button"
                onClick={selectedToken ? resetTokenInfo : fetchTokenInfo}
                disabled={isLoading || !formData.contractAddress}
              >
                {selectedToken ? (
                  "Reset"
                ) : isLoading ? (
                  <Hourglass size={16} />
                ) : (
                  "Search"
                )}
              </SearchButton>
            </InputWithButton>
            {errors.fetchToken && (
              <ErrorMessage>{errors.fetchToken}</ErrorMessage>
            )}
            {errors.wallet && <ErrorMessage>{errors.wallet}</ErrorMessage>}

            {/* Description Input */}
            <label
              style={{
                marginTop: "1rem", // Add 1rem margin on top
              }}
            >
              Description
            </label>
            <TextInput
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              maxLength={250}
              fullWidth
            />
            {errors.description && (
              <ErrorMessage>{errors.description}</ErrorMessage>
            )}
            {/* Ticket Price and Seed Amount side-by-side */}
            <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
              <div style={{ flex: 1 }}>
                <label>Ticket Price</label>
                <TextInput
                  type="number" // gives numeric keypad on mobile, etc.
                  step="0.01" // 2 decimal places
                  min="0.01" // no negatives
                  name="ticketPrice"
                  value={formData.ticketPrice}
                  onChange={(e) => {
                    setFormData({ ...formData, ticketPrice: e.target.value });
                  }}
                  placeholder="Enter ticket price"
                />

                {errors.ticketPrice && (
                  <ErrorMessage>{errors.ticketPrice}</ErrorMessage>
                )}
              </div>

              <div style={{ flex: 1 }}>
                <label>Seed Amount</label>
                <NumberInput
                  defaultValue={formData.seedAmount}
                  step={0.1}
                  min={0}
                  onChange={(value) => {
                    if (value >= 0) {
                      handleNumberChange("seedAmount", value);
                    }
                  }}
                  width="100%"
                />
                {errors.seedAmount && (
                  <ErrorMessage>{errors.seedAmount}</ErrorMessage>
                )}
              </div>
            </div>

            {/* Custom Allocation */}
            <Checkbox
              label="Customize Allocation?"
              name="customAllocationEnabled"
              checked={formData.customAllocationEnabled}
              onChange={handleChange}
            />

            {formData.customAllocationEnabled && (
              <GroupBox>
                <SliderWrapper>
                  <Label>Allocate Ticket Price:</Label>
                  <Slider
                    size="100%"
                    min={5}
                    max={90}
                    value={formData.poolPercentage}
                    onChange={handleSliderChange}
                  />
                  <SliderDetails>
                    <span>
                      Buy Tokens:{" "}
                      {100 -
                        formData.poolPercentage -
                        feePercentages.totalFeesPercentage}
                      %
                    </span>
                    <span>Jackpot/Pool: {formData.poolPercentage}%</span>
                    <span>Protocol: {feePercentages.totalFeesPercentage}%</span>
                  </SliderDetails>
                  {errors.poolPercentage && (
                    <ErrorMessage>{errors.poolPercentage}</ErrorMessage>
                  )}
                </SliderWrapper>
              </GroupBox>
            )}
            {errors.backend && <ErrorMessage>{errors.backend}</ErrorMessage>}
            {errors.submission && (
              <ErrorMessage>{errors.submission}</ErrorMessage>
            )}

            {/* End Date and Time */}
            <label
              style={{
                marginTop: "1rem", // Add 1rem margin on top
              }}
            >
              End Date & Time
            </label>
            <ThemeProvider theme={customTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  value={
                    formData.endDate && formData.endTime
                      ? new Date(`${formData.endDate}T${formData.endTime}`)
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      setFormData({
                        ...formData,
                        endDate: date.toISOString().split("T")[0], // Extract date in yyyy-mm-dd
                        endTime: date.toISOString().split("T")[1].slice(0, 5), // Extract time in HH:mm
                      });
                    }
                  }}
                  minDateTime={
                    ENFORCE_MIN_5_MINUTES
                      ? new Date(Date.now() + 5 * 60_000) // 5 minutes from now
                      : new Date() // or null, or something if you want to fully disable
                  }
                  maxDateTime={feePercentages.globalJackpotDeadline}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      error={!!errors.endDate}
                      helperText={errors.endDate || ""}
                    />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
            {errors.endDate && <ErrorMessage>{errors.endDate}</ErrorMessage>}

            <label
              style={{
                cursor: "pointer",
                marginTop: "1rem", // Add 1rem margin on top
              }}
              onClick={() => setShowOptionalFields(!showOptionalFields)}
            >
              Socials
              {/* Show "▶" when collapsed, "▼" when expanded */}
              <span>{showOptionalFields ? "▼" : "▶"} </span>
            </label>

            {showOptionalFields && (
              <>
                <GroupBox>
                  {/* Styled container for spacing */}
                  <div style={{ marginBottom: "1rem" }}>
                    {/* Website Link */}
                    {formData.websiteLink || showOptionalFields ? (
                      <>
                        <label>Website Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.websiteLink}
                          name="websiteLink"
                          value={formData.websiteLink}
                          onChange={handleChange}
                          placeholder="e.g., google.com"
                          fullWidth
                        />
                        {errors.websiteLink && (
                          <ErrorMessage>{errors.websiteLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div style={{ marginBottom: "1rem" }}>
                    {/* Telegram Link */}
                    {formData.telegramLink || showOptionalFields ? (
                      <>
                        <label>Telegram Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.telegramLink}
                          name="telegramLink"
                          value={formData.telegramLink}
                          onChange={handleChange}
                          placeholder="e.g., t.me/your_telegram"
                          fullWidth
                        />
                        {errors.telegramLink && (
                          <ErrorMessage>{errors.telegramLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div style={{ marginBottom: "1rem" }}>
                    {/* Discord Link */}
                    {formData.discordLink || showOptionalFields ? (
                      <>
                        <label>Discord Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.discordLink}
                          name="discordLink"
                          value={formData.discordLink}
                          onChange={handleChange}
                          placeholder="e.g., discord.gg/your_discord"
                          fullWidth
                        />
                        {errors.discordLink && (
                          <ErrorMessage>{errors.discordLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div style={{ marginBottom: "1rem" }}>
                    {/* X (Twitter) Link */}
                    {formData.xLink || showOptionalFields ? (
                      <>
                        <label>X Link</label>
                        <TextInput
                          as={TextInputStyled}
                          isInvalid={!!errors.xLink}
                          name="xLink"
                          value={formData.xLink}
                          onChange={handleChange}
                          placeholder="e.g., twitter.com/your_handle"
                          fullWidth
                        />
                        {errors.xLink && (
                          <ErrorMessage>{errors.xLink}</ErrorMessage>
                        )}
                      </>
                    ) : null}
                  </div>
                </GroupBox>
              </>
            )}

            <Button
              type="submit"
              disabled={isLoading || !isFormValid()}
              style={{ marginTop: "1rem" }}
            >
              {isLoading ? "Creating..." : "Create Competition"}
            </Button>
          </CompetitionForm>
        </WindowContent>
      </ResponsiveWindow>
    </Wrapper>
  );
};

export default CompetitionPage;
