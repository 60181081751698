import React, { createContext, useContext, useState } from "react";
import { Window, WindowHeader, Button, WindowContent } from "react95";
import styled from "styled-components";

// Styled Components for Popup and Overlay
const PopupWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  width: auto;
  max-width: 600px;

  @media (max-width: 768px) {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

// Create the Context
const AlertContext = createContext();

// Custom hook to use AlertContext
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

// AlertProvider Component
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const showAlert = (message) => {
    setAlert(message);
  };

  const clearAlert = () => {
    setAlert(null);
  };

  return (
    <AlertContext.Provider value={{ showAlert, clearAlert, alert }}>
      {children}
      {alert && (
        <>
          <Overlay onClick={clearAlert} />
          <PopupWrapper>
            <Window>
              <WindowHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Success.eXe</span>
                <Button onClick={clearAlert}>X</Button>
              </WindowHeader>
              <WindowContent>
                <p>{alert}</p>
                <ButtonWrapper>
                  <Button onClick={clearAlert}>Close</Button>
                </ButtonWrapper>
              </WindowContent>
            </Window>
          </PopupWrapper>
        </>
      )}
    </AlertContext.Provider>
  );
};
