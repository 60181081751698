import React, { createContext, useContext, useState } from "react";
import CustomErrorPopup from "../components/CustomErrorPopup"; // Update the path accordingly

const ErrorContext = createContext();

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const showError = (message) => {
    setError(message);
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ showError, clearError, error }}>
      {children}
      <CustomErrorPopup error={error} onClose={clearError} />
    </ErrorContext.Provider>
  );
};
