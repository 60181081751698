import React from "react";
import { Window, WindowHeader, Button, WindowContent } from "react95";
import styled from "styled-components";

const PopupWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  width: auto;
  max-width: 600px;

  @media (max-width: 768px) {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CustomErrorPopup = ({ error, onClose }) => {
  if (!error) return null;

  return (
    <>
      <Overlay onClick={onClose} />
      <PopupWrapper>
        <Window>
          <WindowHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Error.eXe</span>
            <Button onClick={onClose}>X</Button>
          </WindowHeader>
          <WindowContent>
            <p>{error}</p>
            <ButtonWrapper>
              <Button onClick={onClose}>Close</Button>
            </ButtonWrapper>
          </WindowContent>
        </Window>
      </PopupWrapper>
    </>
  );
};

export default CustomErrorPopup;
